.sidebar {
    position:absolute;
    width: 250px;
    background-color:#1D2733;
    left:0px;
    border:none;
}

.main-content {
    transition: transform 0.3s ease;
  }
 
.menu{
 background-color: #1D2733;
 width: 200px;
 height:100vh;
 position:fixed;
 top:0;
 left: -50%;
 transition: 600ms;
}
.menu.active{
 left:0;
 transition: 300ms;
 
}

.menu-list{
    list-style-type: none;
  }

.sidebar-items{
    position:relative;
    color:#fff;
    font-size:18px;
    left:0px;
    width:100px;
    height:70px;
    top:90px;
    align-items: center;
    justify-content: start;
    /* vertical-align: middle; */
    user-select: none;
    /* border-radius:4px; */
}

.sidebar-items a{
    text-decoration: none;
    color: #fff;
}
#sidebar-line{
  color:#fff;
  border: 0.05em solid rgba(255, 255, 255, 0.35);;
  width:164px;
  position:absolute;
  left:16px;
  margin-top:355%;
}