.SignIn {
    display: flex;
    width: 1920px;
    height: 1080px;
  }
  
  .left-section {
    background-color: #F5F5F5;
    width: 66.66%; /* 2/3 of the container */
    height: 100%;
  }
  
  .right-section {
    background-color: #FFFFFF;
    width: 33.33%; /* 1/3 of the container */
    height: 100%;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .custom-button {
    background-color: #7742B9;
    color: #FFFFFF;
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    margin: 10px 0;
    font-family: 'Public Sans', sans-serif;
  }
  
  .header-text, .footer-text {
    font-family: 'Public Sans', sans-serif;
    color: #000000;
    margin-bottom: 20px;
  }
  
  .footer-text span {
    cursor: pointer;
  }
  
  .footer-text span:not(:last-child):after {
    content: '|';
    margin: 0 10px;
  }
  