#menu-icon {
    position:absolute;
    color:#fff;
    top:28px;
    left:32px;
    width:24;
    height:24;
    cursor:pointer;
}

#bell-icon{
    position:absolute;
    color:#fff;
    top:29px;
    right:127px;
    width:22px;
    height:22px;
    cursor:pointer;
}

#bell-icon-background{
    position:absolute;
    border-radius: 10px;
    top:20px;
    right:118px;
    width:40px;
    height:40px;
    flex-shrink: 0;
}

#setting-icon{
    position:fixed;
    color:#fff;
    top:29px;
    right:63px;
    width:22px;
    height:22px;
    cursor:pointer;
}
#setting-icon-background{
    position:absolute;
    border-radius: 10px;
    top:20px;
    right:54px;
    width:40px;
    height:40px;
    flex-shrink: 0;
}

#np-icon{
    width: 49.848px;
    height: 48.16px;
    flex-shrink: 0;
    margin-top:10px;
}

#ep-icon{
    width: 47.313px;
    height: 47px;
    flex-shrink: 0;
    margin-top:10px;
}

.check-icon{
    /* position:absolute;
    right:700px; */
    flex-shrink: 0;
}