.new-product{
    width:452px;
    /* margin-left:170px; */
    /* margin-right:632px; */
    height:260px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #FFF;
    align-self: center;
    margin: 1rem 1rem 0rem 0rem;
    box-shadow: 0px 5px 21px 0px rgba(155, 155, 155, 0.10), 0px 10px 37px 9px rgba(155, 155, 155, 0.20);
    cursor:pointer;

}
.new-product.select{
    border-radius: 10px;
    border: 3px solid #7742B9;
    box-shadow: 0px 5px 21px 0px rgba(155, 155, 155, 0.10), 0px 10px 37px 9px rgba(155, 155, 155, 0.20);
}

.existing-product{
    width: 452px;
    height: 260px;
    flex-shrink: 0;
    border-radius: 10px;
    border:none;
    /* border: 3px solid #7742B9; */
    margin: 1rem 0rem 0rem 1rem;
    background: #FFF;
    align-self: center;
    box-shadow: 0px 5px 21px 0px rgba(155, 155, 155, 0.10), 0px 10px 37px 9px rgba(155, 155, 155, 0.20);
    cursor:pointer;
}

.existing-product.select{
    border-radius: 10px;
    border: 3px solid #7742B9;
    box-shadow: 0px 5px 21px 0px rgba(155, 155, 155, 0.10), 0px 10px 37px 9px rgba(155, 155, 155, 0.20);
}

.outer-circle{
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    stroke-width: 2px;
    stroke: #D1D6DA;
}

.outer-circle.select{
    stroke: #7742B9;
}
.inner-circle{
    stroke-width: 0px;
}
.inner-circle.select{
    fill:#7742B9;
    stroke-width: 0px;
}
/* .circle{
    position:absolute;
    top:600px;
    left:550px;
} */