.input-wrapper{
    position:relative;
}

.input-icon{
  padding:4px;
  position: absolute;
  box-sizing:border-box;
  top:5px;
  left:825px;
}

.input-box{
    width: 492px;
    height: 46px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #F3F7FC;
    border:none;
    text-indent: 10px;
    color: #1D2733;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}