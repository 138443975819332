.nav-container{
    position:absolute;
    bottom:0px;
    border:none;
    left:0px;
}

.nav-item{
    position:static;
    background-color: #1D2733;
    width:150px;
    height:60px;
    border:none;
    box-shadow: none;
    align-items: center;
}
#profileDropDown {
    background-color: #1D2733;
    width:170px;
    height:50px;
    border:none;
    user-select: none;
    align-items: center;
  }
.nav-user-profile{
    width:40px;
}