.header {
    color:#1D2733;
    background-color: #1D2733;
    position: fixed;
    width:100%;
    height:80px;
    max-width:100%;
    top:0;
  }
  
  /* Styles for title */
  .company-name {
    position: absolute;
    margin-top: 24px;
    margin-left: 72px;
    font-size: 24px;
    cursor: pointer;
    font-style: normal;
    font-weight: 800;
    color: #fff;
    line-height: 32px;
    letter-spacing: -0.24px;
    font-family: "Public Sans";
    user-select: none;
  }

.company-name:hover{
    color:#fff;
    text-decoration:none;
}