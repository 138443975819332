.outerbox{
    position:inherit;
    width:calc(100vw - 268px);
    margin-left:134px;
    margin-top:106px;
    height: calc(100vh - 132px);
    flex-shrink: 0;
    border-radius: 6px;
    border: 1px solid var(--Deep-Grey---200, #E8E9EB);
    background: var(--White, #FFF);
    box-shadow: 0px 5px 10px 0px rgba(155, 155, 155, 0.15), 0px 10px 30px 0px rgba(155, 155, 155, 0.15);
    overflow-y: scroll;
    text-align:center;
    /* justify-content: center;
    align-items: center; */
}

.get-started{
    color: #79689F;
    font-size: 11px;
    font-style: normal;
    font-weight: 800;
    line-height: 27px; /* 192.857% */
    letter-spacing: 0.28px;
    margin-top:38px;
}

.ai-pathway{
    color: #1D2733;
    display:inline-block;
    vertical-align: middle;
    width: 800px;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    flex-shrink: 0;
    margin-top:10px;
}

/* .select-product{
    color: #1D2733;
    width: 800px;
    display:inline-block;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    margin-top:0px;
} */
.line{
    width: 58px;
    height: 2px;
    flex-shrink: 0;
    /* display:inline-block; */
    border-radius: 100px;
    background: #D5DEEC;
    margin-top:0px;
}


.existing-product{
    width: 452px;
    height: 260px;
    flex-shrink: 0;
    border-radius: 10px;
    border:none;
    /* border: 3px solid #7742B9; */
    margin: 1rem 0rem 0rem 1rem;
    background: #FFF;
    align-self: center;
    box-shadow: 0px 5px 21px 0px rgba(155, 155, 155, 0.10), 0px 10px 37px 9px rgba(155, 155, 155, 0.20);
}

.product-title{
    color: #1D2733;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.aParent{
    /* float:left; */
    text-align: center;
    display:flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}