.App {
  display: flex;
  height: 100vh; 
}

.sidebar {
  flex: 0 0 30%;
  background-color: #f0f0f0; 
  padding: 20px;
  border-right: 1px solid #ccc; 
}

.sidebar h2 {
  margin: 0;
}

.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar li {
  cursor: pointer;
  padding: 5px;
  border: 1px solid #ccc;
  margin-bottom: 5px;
  background-color: #fff;
}


.content {
  flex: 1; 
  padding: 20px;
}

.file-content {
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ccc;
}

@media (max-width: 768px) {
  .App {
    flex-direction: column;
  }

  .sidebar {
    flex: 1;
    border-right: none;
    border-bottom: 1px solid #ccc;
  }
}
