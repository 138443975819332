/* SearchBar.css */
.search-bar-container {
    align-items: center;
    justify-content: center;
  }
  
  .SearchBar{
    display:flex;
    margin-top:20px;
    margin-left:352px;
    margin-right:352px;
    margin-bottom:20px;
}
.SearchBar input::-webkit-input-placeholder{
    color: #98A3B0;
    text-indent:52px;
    /* text-align:center; */
    vertical-align: middle;
    user-select: none;
  }
.search-icon {
    position: fixed;
    left: 368px;
    top:29px;
    fill: white; 
    width: 22px; 
    height: 22px;
    vertical-align: middle;
    cursor:text;
  }
.SearchBar input{
    width:1216px;
    height:40px;
    border-radius:10px;
    border: 0px solid;
    background-color:#4C5A6B;
    text-indent:52px;
}
