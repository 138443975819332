/* src/App.css */

.App {
    display: flex;
}

.App-header {
    flex: 3;
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-header h1 {
    margin-bottom: 20px;
}

.chat-history {
    max-height: 300px;
    overflow-y: auto;
    width: 80%;
    margin-top: 20px;
}

.user-message, .bot-message {
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
    width: 70%;
}

.user-message {
    background-color: #F7F7F8;
    align-self: flex-start;
}

.bot-message {
    background-color: #FFFFFF;
    align-self: flex-end;
}

.sidebar {
    flex: 1;
    background-color: #202123;
    padding: 20px;
}

.sidebar h2 {
    margin-top: 0;
}
